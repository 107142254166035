import React, { useState, useEffect } from "react";
import { Button } from "./Button";
import "./Navbar.css";
import MyCV from "./images/CV.pdf";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  const openPdf = () => {
    window.open(MyCV);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <a href="/" className="navbar-logo" onClick={closeMobileMenu}>
            Carla
          </a>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <a href="/" className="nav-links" onClick={closeMobileMenu}>
                Hem
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/#projects"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Projekt
              </a>
            </li>
            <li className="nav-item">
              <a href="/#about" className="nav-links" onClick={closeMobileMenu}>
                Om mig
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/#contact"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Kontakt
              </a>
            </li>
          </ul>
          {button && (
            <Button buttonStyle="btn--outline" onClick={openPdf}>
              Visa CV
              <i
                className="fas fa-external-link-alt"
                style={{ marginLeft: "5px" }}
              />
            </Button>
          )}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
