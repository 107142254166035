import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import Project1 from "./components/pages/Project1";
import Project2 from "./components/pages/Project2";
import Project3 from "./components/pages/Project3";
import Project4 from "./components/pages/Project4";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/polarbrod" element={<Project1 />} />
        <Route path="/spsm" element={<Project2 />} />
        <Route path="/1step" element={<Project3 />} />
        <Route path="/icebreakers" element={<Project4 />} />
      </Routes>
    </Router>
  );
}

export default App;
