import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";
import polarbrodcover from "./images/polarbrod/polarbrodcover.png";
import spsmcover from "./images/spsm/spsmcover.png";
import firststepcover from "./images/1step/1stepcoverimage.png";
import icebreakerscover from "./images/icebreakers/grandpa-coverpicture.png";

function Cards() {
  return (
    <div className="cards">
      <h1>Projekt</h1>
      <div className="cards__container" id="projects">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src={polarbrodcover}
              text="Smörgåsverktyg"
              description="Ett koncept till ett smörgåsverktyg beställt av Polarbröd, mer specifikt deras innovationslabb Smedjan. Uppdraget var att undersöka och ta fram en digital lösning där konsumenter kan vara en del av processen med framtagning av nya produkter."
              label="UX & UI Design"
              path="/polarbrod"
            />
            <CardItem
              src={spsmcover}
              text="Bildstödsplattform"
              description="Specialpedagogiska skolmyndigheten (SPSM) erhåller bildstödsbanker för elever som behöver olika typer av bildstöd i undervisning och vardagen. Uppdraget var att uppdatera deras två bildstödsbanker som var utdaterade och behövde ett uppdaterat koncept för en kombination av bildstördbankerna."
              label="UX & UI Design"
              path="/spsm"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src={firststepcover}
              text="Brand Identity"
              description="1step.se är ett företag som sammankopplar företag med stundeter i Västerbotten. Mitt uppdrag var att ta fram visuella och kommunikativa riktlinjer kring hur de ska visa och använda sitt varumärke. Detta inkluderade logotyp, riktlinjer, färger och texttyper."
              label="Grafisk Design"
              path="/1step"
            />
            <CardItem
              src={icebreakerscover}
              text="Icebreakers"
              label="UX & UI Design"
              path="/icebreakers"
              description="I kursen teknik för sociala medier var uppdraget att skapa ett socialt medium för äldre. Resultatet blev Icebreakers, en applikation där äldre kan hitta nya personer att skriva med för att förhoppningsvis knyta nya kontakater."
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
