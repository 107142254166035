import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";
import "./ContactSection.css";

function ContactSection() {
  return (
    <div className="contact-container" id="contact">
      <h1>Kontakt</h1>
      <p>Om du vill komma i kontakt med mig så kan du maila mig på:</p>
      <div className="contact-btns">
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          href="mailto:jobb.carla@gmail.com"
        >
          jobb.carla@gmail.com &rarr;
        </Button>
      </div>
    </div>
  );
}

export default ContactSection;
