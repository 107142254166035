import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container" id="home">
      <p>Hej! Jag heter Carla och är (nästan färdig)</p>
      <h1>Interaktionsdesigner</h1>

      <div className="hero-btns"></div>
    </div>
  );
}

export default HeroSection;
