import React from "react";
import "../../App.css";
import Cards from "../Cards";
import ContactSection from "../ContactSection";
import HeroSection from "../HeroSection";
import About from "../AboutSection";

function Home() {
  return (
    <>
      <HeroSection />
      <Cards />
      <About />
      <ContactSection />
    </>
  );
}

export default Home;
