import React from "react";
import "./AboutSection.css";
import pic1 from "./images/selfie1.png";
import pic2 from "./images/selfie2.jpeg";

function About() {
  return (
    <div className="about-container" id="about">
      <h1>Om mig</h1>
      <div className="about-content">
        <div className="about-img1">
          <img src={pic1} alt="Pic 1" />
        </div>
        <div className="about-img2">
          <img src={pic2} alt="Pic 2" />
        </div>
        <div className="about-text">
          <p>
            Kul att du hittade hit! Jag är 23 år gammal och studerar för
            närvarande mitt femte och sista år på Civilingenjörs-programmet
            Interaktion och Design på Umeå Universitet.{" "}
          </p>
          <p>
            Jag började studera programmet tack vare mitt stora intresse för
            teknik och kreativitet, och har trivts väldigt bra! På min fritid
            gillar jag att vara kreativ och självklart att umgås med hunden på
            bilden 🐶
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
